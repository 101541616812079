<template>
  <el-input :value="$attrs.value">
    <div
      slot="append"
      :class="btnClass"
      class="xa-cursor"
      :data-clipboard-text="$attrs.value"
      @click="() => false"
    >
      复制
    </div>
  </el-input>
</template>
<script>
import Clipboard from 'clipboard'
let _id = 0
export default {
  data() {
    _id++
    return {
      btnClass: 'Clipboard-btn--' + _id,
      done: null,
    }
  },
  beforeDestroy() {
    this.done && this.done()
  },
  mounted() {
    var vm = this
    var el = document.getElementsByClassName(this.btnClass)[0]
    var clipboard = new Clipboard(el)
    clipboard.on('success', function(e) {
      e.clearSelection()
      vm.$message.success('☺ 复制成功！')
    })
    clipboard.on('error', function(e) {
      window.console.error('Action:', e.action)
      window.console.error('Trigger:', e.trigger)
      vm.$message.info('☺ 复制成功！')
    })
    this.done = function() {
      clipboard && clipboard.destroy()
    }
  },
}
</script>
<style lang="scss" scoped></style>
